import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideNavComponent } from '@enginuity/core/organisms/side-nav/side-nav.component';
import { TopNavComponent } from '@enginuity/core/organisms/top-nav/top-nav.component';
import { LogoLoaderComponent } from '@enginuity/core/organisms/logo-loader/logo-loader.component';

@Component({
  selector: 'app-organization-layout',
  standalone: true,
  imports: [RouterOutlet, SideNavComponent, TopNavComponent, LogoLoaderComponent],
  templateUrl: './organization-layout.component.html',
  styleUrl: './organization-layout.component.scss',
})
export class OrganizationLayoutComponent {
  protected expand_sidebar: boolean = false;
  protected show_layout: boolean = true;
  protected topNav: any = 'no';
  protected sideNav: any = 'no';
}
