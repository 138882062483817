import { Component, Input } from '@angular/core';
import { AnimationOptions, LottieComponent, LottieDirective } from 'ngx-lottie';

@Component({
  selector: 'app-logo-loader',
  standalone: true,
  templateUrl: './logo-loader.component.html',
  styleUrl: './logo-loader.component.scss',
  imports: [LottieComponent, LottieDirective],
})
export class LogoLoaderComponent {
  options: AnimationOptions = {
    path: './assets/logo-animation.json',
  };
  @Input() title: string | undefined = 'Loading...';
  @Input() message: string | undefined = 'Please wait...';
}
