import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  TimeProtectionEntry,
  TimeProtectionService,
} from '@services/core-services/time-protection.service';
import { of, tap } from 'rxjs';
import { SignupUser } from '@services/onboarding-services/models';
import { SignupService } from '@services/onboarding-services/signup.service';
import { catchError } from 'rxjs/operators';
import { OTPToken, TenantCreate } from '@services/tenant-services/models';

@Component({
  selector: 'app-signup-otp-form',
  standalone: true,
  imports: [
    ButtonsComponent,
    CeIconComponent,
    LinkBtnComponent,
    NgIf,
    NgOptimizedImage,
    NgOtpInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './signup-otp-form.component.html',
  styleUrl: './signup-otp-form.component.scss',
})
export class SignupOtpFormComponent implements OnChanges {
  @Input({ required: true }) user: SignupUser | undefined | null;
  @Input({ required: true }) otp: OTPToken | undefined | null;

  @Output() onEmailVerified = new EventEmitter();

  protected readonly OtpLength = 6;
  protected readonly ResendWaitingTime = 20;
  protected processing: boolean = false;
  protected timer: TimeProtectionEntry = {
    blocked: false,
    count: 0,
    countdown: this.ResendWaitingTime,
  };

  protected form = this.fb.group({
    otp_token: ['', [Validators.required]],
    otp: ['', [Validators.required, Validators.minLength(this.OtpLength)]],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly timeProtectionService: TimeProtectionService,
    private readonly signupService: SignupService
  ) {
    this.timeProtectionService.valueChanges.subscribe(timer => (this.timer = timer));
  }

  ngOnChanges(changes: SimpleChanges) {
    const otp = changes['otp']?.currentValue as OTPToken;
    if (otp) {
      this.form.get('otp_token')?.setValue(otp.otp_token as never);
    }
  }

  onOTPChange(value: string) {
    this.form.get('otp')?.setValue(value);
  }

  resendOTP() {
    this.timeProtectionService.blockAndStartCounting(this.ResendWaitingTime);
    const { email } = this.user!;
    this.signupService.sendVerificationMail(email!).subscribe();
  }

  verifyEmailWithOTP() {
    const { otp, otp_token } = this.form.getRawValue();
    this.signupService
      .verifyEmail(otp!, otp_token!)
      .pipe(
        tap(() => this.onEmailVerified.emit()),
        catchError(err => this.handleOTPError(err))
      )
      .subscribe();
  }

  private handleOTPError(err: any) {
    this.form.get('otp')?.setErrors({ invalid: true });
    return of(err);
  }
}
