@if (show_layout) {
  <header><app-top-nav [limited]="topNav"></app-top-nav></header>
}
@if (show_layout) {
  <div class="main-nav">
    <app-side-nav [limited]="sideNav"></app-side-nav>
  </div>
}
<div
  class="d-flex flex-column flex-row-fluid wrapper pt spacing-40"
  [class.spacing-40]="show_layout"
  [id]="show_layout ? 'ce-wrapper' : ''">
  <router-outlet></router-outlet>
</div>
