import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BadgesComponent } from '@enginuity/core/molecules/badges/badges.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { JsonPipe } from '@angular/common';
import { SignupService } from '@services/onboarding-services/signup.service';
import { SignupUser } from '@services/onboarding-services/models';
import { of } from 'rxjs';
import { AuthError, AuthErrorsTypes } from '@services/auth-services/models';

@Component({
  selector: 'app-signup-email-form',
  standalone: true,
  imports: [BadgesComponent, InputsComponent, ReactiveFormsModule, ButtonsComponent, JsonPipe],
  templateUrl: './signup-email-form.component.html',
  styleUrl: './signup-email-form.component.scss',
})
export class SignupEmailFormComponent {
  @Input() processing = false;

  @Output() onSignUp = new EventEmitter();

  protected form = this.fb.group({
    first_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
    last_name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/)]],
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/),
      ],
    ],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly signupService: SignupService
  ) {}

  handleSignup() {
    if (!this.form.valid) return;

    this.processing = true;

    const user = this.form.getRawValue();
    this.signupService.signUp(user as SignupUser).subscribe({
      next: () => {
        this.onSignUp.emit();
        return (this.processing = false);
      },
      error: err => this.catchErrors(err?.error),
    });
  }

  getState(name: string): 'default' | 'inactive' | 'success' | 'warning' | 'focused' {
    const control = this.form.get(name);
    const touched = control?.touched;

    return touched && control?.errors ? 'warning' : 'default';
  }

  getErrors(name: string) {
    const control = this.form.get(name);
    const errors = control?.errors;

    if (!errors) return null;

    if (errors?.['duplicate']) {
      return 'This email is already taken';
    }

    if (name === 'password' && errors?.['pattern']) {
      return '6+ letters /  1+ number / 1+ symbol';
    }

    if (errors?.['pattern']) {
      return 'Only letters allowed';
    }

    if (errors?.['email']) {
      return 'Invalid email format';
    }

    if (errors?.['required']) return 'This field is required`';

    // TODO: Handle other errors
    return null;
  }

  private catchErrors(err: any) {
    //if (err?.code === AuthErrorsTypes.EMAIL_EXISTS) {
    // TODO: API returns different email
    this.form.get('email')?.setErrors({ duplicate: true });
    //}
    return of(err);
  }
}
