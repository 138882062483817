import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormErrorsService } from '@services/form-services/form-errors.service';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { UrlService } from '@services/auth-services/url.service';
import { TenantService } from '@services/tenant-services/tenant.service';
import { OnboardingTitleComponent } from '@enginuity/onboarding/organisms/onboarding-title/onboarding-title.component';
import { OnboardingHeaderComponent } from '@enginuity/onboarding/organisms/onboarding-header/onboarding-header.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  standalone: true,
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    LinkBtnComponent,
    ReactiveFormsModule,
    InputsComponent,
    ButtonsComponent,
    SecondaryBtnsComponent,
    NgOptimizedImage,
    OnboardingTitleComponent,
    OnboardingHeaderComponent,
  ],
})
export class SigninComponent implements OnInit {
  suffixBadge = {
    icon: 'no_icon',
    type: 'white',
    label: environment.baseHost,
    rightIcon: true,
  };
  create = false;
  loaderButton = false;
  loginForm: FormGroup;
  subdomain_state: 'default' | 'inactive' | 'success' | 'warning' | 'focused' = 'default';
  subdomain_error = '';

  constructor(
    private readonly router: Router,
    public readonly tenantService: TenantService,
    public readonly urlService: UrlService,
    private readonly fb: FormBuilder,
    private readonly formErrorsService: FormErrorsService
  ) {
    this.loginForm = this.fb.group({
      subdomain: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*[a-z0-9]+$'),
          Validators.minLength(4),
          Validators.maxLength(20),
        ],
      ],
    });
  }

  ngOnInit() {
    const { host } = this.urlService.getUrlSettings();
    this.suffixBadge.label = host || '';
    this.loginForm.get('subdomain')?.valueChanges.subscribe(value => {
      this.setSubdomainError();
      this.loginForm.get('subdomain')?.setValue(value.toLowerCase(), { emitEvent: false });
    });
  }
  goToForgotDomain() {
    this.router.navigate(['signin', 'recover']);
  }

  onSubmit(): void {
    this.loaderButton = true;
    let subdomain = this.loginForm.value.subdomain;

    this.tenantService.getTenantBySubdomain(subdomain).subscribe({
      next: () => this.urlService.redirectToPath(subdomain, '/auth/login'),
      error: err => {
        this.subdomain_state = 'warning';
        this.subdomain_error = `Subdomain '${subdomain}' not found`;
      },
      complete: () => (this.loaderButton = false),
    });
  }

  back() {
    this.create = false;
  }

  getErrorMsg(control: AbstractControl | null, field = '') {
    if (control?.touched) {
      return this.formErrorsService.toMsg(control.errors, field, control.value);
    }
    return null;
  }

  setSubdomainError() {
    let error_message = this.getErrorMsg(this.loginForm.get('subdomain'), '');
    if (error_message) {
      this.subdomain_state = 'warning';
      this.subdomain_error = error_message;
    } else {
      this.subdomain_state = 'default';
      this.subdomain_error = '';
    }
  }
}
