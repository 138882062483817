import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { LinkBtnComponent } from '@enginuity/core/molecules/tertiary/link-btn.component';
import { InputsComponent } from '@enginuity/core/molecules/inputs/inputs.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { OnboardingTitleComponent } from '@enginuity/onboarding/organisms/onboarding-title/onboarding-title.component';
import { OnboardingHeaderComponent } from '@enginuity/onboarding/organisms/onboarding-header/onboarding-header.component';
import { CeIconComponent } from '@enginuity/core/atoms/ce-icon/ce-icon.component';
import {
  TimeProtectionEntry,
  TimeProtectionService,
} from '@services/core-services/time-protection.service';
import { UrlService } from '@services/auth-services/url.service';
import { TenantService } from '@services/tenant-services/tenant.service';
import { NotifyService } from '@services/core-services/notify.service';

enum Step {
  Initial = 'Initial',
  Sent = 'Sent',
}

@Component({
  selector: 'app-recover-subdomain',
  standalone: true,
  templateUrl: './recover-subdomain.component.html',
  styleUrls: ['./recover-subdomain.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    LinkBtnComponent,
    ReactiveFormsModule,
    InputsComponent,
    ButtonsComponent,
    SecondaryBtnsComponent,
    NgOptimizedImage,
    OnboardingTitleComponent,
    OnboardingHeaderComponent,
    CeIconComponent,
  ],
})
export class RecoverSubdomainComponent {
  protected create = false;
  protected loaderButton = false;
  protected Step = Step;
  protected step = Step.Initial;
  protected readonly ResendWaitingTime = 20;
  protected processing: boolean = false;
  protected timer: TimeProtectionEntry = {
    blocked: false,
    count: 0,
    countdown: this.ResendWaitingTime,
  };

  protected form = this.fb.group({
    email: ['', [Validators.required, Validators.minLength(4), Validators.email]],
  });

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly urlService: UrlService,
    private readonly notify: NotifyService,
    private readonly timeProtectionService: TimeProtectionService,
    private readonly tenantService: TenantService
  ) {
    this.timeProtectionService.valueChanges.subscribe(timer => (this.timer = timer));
  }

  onSubmit() {
    const { email, continueUrl } = this.getBaseDetails();
    this.tenantService.sendSubdomainRecoveryEmail(email!, continueUrl).subscribe({
      next: () => this.handleEmailSent(),
      error: () => this.handleEmailError(),
    });
  }

  resend() {
    this.timeProtectionService.blockAndStartCounting(this.ResendWaitingTime);
    const { email, continueUrl } = this.getBaseDetails();
    this.tenantService.sendSubdomainRecoveryEmail(email!, continueUrl).subscribe({
      next: () => this.handleEmailSent(),
      error: () => this.handleEmailError(),
    });
  }

  onCheckInbox() {
    this.urlService.redirect('https://mail.google.com/');
  }

  goBack() {
    this.router.navigate(['/signin']);
  }

  private getBaseDetails() {
    const { email } = this.form.getRawValue();
    const { protocol, host } = this.urlService.getUrlSettings();
    const continueUrl = `${protocol}//${host}/signin`;

    return { email, continueUrl };
  }

  private handleEmailSent() {
    this.step = Step.Sent;
    this.loaderButton = false;
  }

  private handleEmailError() {
    this.notify.error('Email does not exist');
  }
}
