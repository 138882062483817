import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { OnboardingLayoutComponent } from './layouts/onboarding-layout/onboarding-layout.component';
import { LoginLayoutComponent } from './login-module/login-layout/login-layout.component';
import { SignupComponent } from './onboarding-module/signup/signup.component';
import {
  hybridGuard,
  tenantAndUserGuard,
  tenantGuard,
  userGuard,
} from '@services/core-services/guards';
import { SigninComponent } from './onboarding-module/signin/signin.component';
import { RecoverSubdomainComponent } from './onboarding-module/recover-subdomain/recover-subdomain.component';
import { OrganizationLayoutComponent } from './layouts/organization-layout/organization-layout.component';
import { storeSlugGuard } from '@services/core-services/store-slug.guard';
import { orderedAsyncGuards } from '@services/core-services/utils/guards';

const routes: Routes = [
  {
    path: 'signup',
    component: OnboardingLayoutComponent,
    children: [
      {
        path: '',
        component: SignupComponent,
      },
    ],
  },
  {
    path: 'signin',
    component: OnboardingLayoutComponent,
    children: [
      {
        path: '',
        component: SigninComponent,
      },
      {
        path: 'recover',
        component: RecoverSubdomainComponent,
      },
    ],
  },
  {
    path: 'onboarding',
    canActivate: [userGuard],
    component: OnboardingLayoutComponent,
    loadChildren: () =>
      import('src/app/onboarding-module/onboarding-module-routing.module').then(m => m.routes),
  },
  {
    path: 'organisation',
    component: OrganizationLayoutComponent,
    canActivate: [tenantAndUserGuard],
    loadChildren: () =>
      import(
        'src/app/setting-module/organization-settings/organization-settings-routing.module'
      ).then(m => m.routes),
    title: 'organisation-settings',
  },
  {
    path: 'auth',
    canActivate: [tenantGuard],
    component: LoginLayoutComponent,
    loadChildren: () =>
      import('src/app/login-module/login-module-routing.module').then(m => m.routes),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [orderedAsyncGuards([hybridGuard, storeSlugGuard])],
    loadChildren: () => import('src/app/modules-routing.module').then(m => m.routes),
  },
  {
    path: ':store_slug/:app_mode',
    component: MainLayoutComponent,
    canActivate: [hybridGuard, storeSlugGuard],
    loadChildren: () => import('src/app/modules-routing.module').then(m => m.routes),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
