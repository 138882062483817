import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideNavComponent } from '@enginuity/core/organisms/side-nav/side-nav.component';
import { TopNavComponent } from '@enginuity/core/organisms/top-nav/top-nav.component';

@Component({
  selector: 'app-onboarding-layout',
  standalone: true,
  imports: [RouterOutlet, SideNavComponent, TopNavComponent],
  templateUrl: './onboarding-layout.component.html',
  styleUrl: './onboarding-layout.component.scss',
})
export class OnboardingLayoutComponent {}
